<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <v-card-title>
          <v-toolbar
              flat
          >
            <v-toolbar-title class="font-weight-bold text-h4 text-typo mb-0">
              <div v-if="$store.state.auth.account">
                Snapshot
                -
                <span style="color: #e98f41">{{ $store.state.auth.user.store_name }}</span>
              </div>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-data-table dense :headers="header" :items="items">
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col class="float-right" cols="12">
                     <v-text-field
                      hide-details
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                      "
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="ricerca"
                      placeholder="Ricerca Per Data"
                  >
                    <template slot="prepend-inner">
                      <v-icon
                          color="#adb5bd"
                          size="16"
                          class="material-icons-round mt-n2"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      :loading="buttonLoading"
                      height="43"
                      class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
                      @click.prevent="creaSnapshot">
                      Crea Snapshot
                    </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.created_at`]="{item}">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:[`item.action`]="{item}">
              <v-btn :loading="loadingStates[item.id]" icon small @click="downloadExcel(item)">
                <v-icon color="green" class="mr-3">mdi-microsoft-excel</v-icon>
              </v-btn>
              <v-btn icon small @click="deleteSnapshot(item)">
                <v-icon color="red" class="mr-3">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
        >Vuoi eliminare questo Snapshot?
        </v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
              @click="closeDelete"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                        font-weight-normal
                        text-capitalize
                        btn-ls
                        bg-transparent
                        btn-outline-secondary
                        py-3
                        px-6
                      "
          >Cancel
          </v-btn
          >
          <v-btn
              @click="deleteItemConfirm"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      "
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import WarehouseService from "@/services/warehouse.service";

export default {
  data() {
    return {
      loading: false,
      buttonLoading: false,
      loadingStates: {},
      header: [
        {'value': 'id', text: '#ID'},
        {'value': 'created_at', text: 'Date'},
        {'value': 'action', text: 'Action'},
      ],
      items: [],
      item: {},
      dialogDelete: false,
      ricerca:null,
    }
  },
  mounted() {
    this.initSnapshot()
  },
  methods: {
    initSnapshot () {
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      WarehouseService.fetchSnapshot(params).then(resp => {
        this.items = resp.data.results
      }).catch().finally()
    },
    creaSnapshot () {
      this.buttonLoading = true
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      WarehouseService.createSnapshot(params).then(() =>{
        this.$dialog.message.success("Snapshot creato correttamente", {
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: false,
          timeout: 1000,
        });
      }).catch().finally(() => {
        this.buttonLoading = false
        this.initSnapshot()
      })
    },
    downloadExcel (item) {
      this.$set(this.loadingStates, item.id, true)
      WarehouseService.downloadExcel(item.id).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'snapshot.xlsx');
        document.body.appendChild(link);
        link.click();
      })
          .catch(error => console.log(error)).finally(() => {
        this.$set(this.loadingStates, item.id, false)
      })
    },
    closeDelete () {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      WarehouseService.deleteSnapshot(this.item.id).then(() => {
        this.$dialog.message.success("Snapshot eliminato correttamente", {
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: false,
          timeout: 1000,
        });
      }).catch(() => {
        this.$dialog.message.error("Eliminazione Snapshot non avvnuta", {
          rounded: true,
          position: "top-right",
          color: "red accent-5",
          outlined: false,
          timeout: 1000,
        });
      }).finally(() => {
        this.closeDelete()
        this.initSnapshot()
      })
    },
    deleteSnapshot(item) {
      this.item = item
      this.dialogDelete = true
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      // Mappa dei nomi dei mesi in italiano
      const monthNames = [
        "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
        "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
      ];

      // Ottieni giorno, mese e anno
      const day = date.getDate();
      const month = monthNames[date.getMonth()]; // Usa il mese come indice per trovare il nome del mese
      const year = date.getFullYear();

      // Formatta la data in DD-Mese-YYYY
      return `${day.toString().padStart(2, '0')} ${month} ${year}`;
    }
  }
}
</script>